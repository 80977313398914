var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    {
      staticClass: "c-card",
      attrs: { "data-did": _vm.card.dyadey_id, "data-cy": "card" },
    },
    [
      _c("div", { staticClass: "c-card__inner" }, [
        _c(
          "aside",
          {
            class: ["c-card__menu-cta", { open: _vm.showContextMenu }],
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.toggleContextMenu.apply(null, arguments)
              },
            },
          },
          [_vm._v(" ")]
        ),
        _vm._v(" "),
        _c("header", [
          _c(
            "figure",
            {
              class: [
                _vm.card.post_network_id === _vm.$simpleStore.Networks.DY &&
                _vm.card.post_show_community_img !== 1 &&
                typeof _vm.$root.community != "undefined" &&
                _vm.$root.community.id > 0
                  ? "ugc"
                  : "",
              ],
            },
            [
              _vm.card.post_network_id === _vm.$simpleStore.Networks.DY &&
              _vm.card.post_show_community_img !== 1 &&
              typeof _vm.$root.community != "undefined" &&
              _vm.$root.community.id > 0
                ? _c("a", { attrs: { href: "/u/" + _vm.card.user_id } }, [
                    _c("img", {
                      attrs: {
                        src: _vm.card.user_avatar,
                        alt: _vm.card.cAlt,
                        width: "50",
                        height: "50",
                      },
                    }),
                  ])
                : _c(
                    "a",
                    {
                      staticClass: "c-cards__default-community-icon",
                      attrs: { href: "/" + _vm.card.community_url },
                    },
                    [
                      _vm.card.community_id > 0 &&
                      parseInt(_vm.card.community_id) !==
                        _vm.$root.placeholderCommunityId
                        ? _c("img", {
                            attrs: {
                              src: _vm.$simpleStore.cloudinaryUrl(
                                50,
                                50,
                                "img/communities/" +
                                  _vm.card.community_id +
                                  "/" +
                                  _vm.card.community_thumbnail,
                                _vm.$root
                              ),
                              alt: _vm.card.community_name,
                              width: "50",
                              height: "50",
                            },
                          })
                        : _c("img", {
                            attrs: {
                              src: "/img/icons/icon-communities-squared--contained.svg",
                              width: "50",
                              height: "50",
                              alt: "Default Community Image",
                            },
                          }),
                    ]
                  ),
            ]
          ),
          _vm._v(" "),
          _c("section", [
            _vm.card.repost_id > 0
              ? _c("div", { staticClass: "u-flex u-flex-align-start" }, [
                  _c("small", [
                    _c("span", { staticClass: "c-card__postedin" }, [
                      _c("a", { attrs: { href: "/u/" + _vm.card.username } }, [
                        _vm._v(
                          _vm._s(_vm.card.user_forename) +
                            " " +
                            _vm._s(_vm.card.user_surname)
                        ),
                      ]),
                    ]),
                    _vm._v("reposted from"),
                  ]),
                ])
              : _vm.card.post_network_id === 7 &&
                _vm.card.post_is_draft === true &&
                _vm.card.community_id > 1
              ? _c("div", { staticClass: "u-flex u-flex-align-start" }, [
                  _vm._m(0),
                ])
              : _vm.card.post_is_draft === true
              ? _c("div", { staticClass: "u-flex u-flex-align-start" }, [
                  _vm._m(1),
                ])
              : _vm.card.post_network_id === 7
              ? _c("div", { staticClass: "u-flex u-flex-align-start" }, [
                  _c("small", [
                    _c("span", { staticClass: "c-card__postedin" }, [
                      _c("a", { attrs: { href: "/u/" + _vm.card.username } }, [
                        _vm._v(
                          _vm._s(_vm.card.user_forename) +
                            " " +
                            _vm._s(_vm.card.user_surname)
                        ),
                      ]),
                    ]),
                    _vm._v("posted in"),
                  ]),
                ])
              : _c("div", { staticClass: "u-flex u-flex-align-center" }, [
                  _c("small", [_vm._v("Post from")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "c-card__social-from" },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "/" +
                              _vm.card.community_url +
                              "/" +
                              _vm.card.dyadey_id,
                          },
                        },
                        [
                          _vm.card.post_network_id ===
                          _vm.$simpleStore.Networks.FB
                            ? _c("img", {
                                attrs: {
                                  src: "/img/icons/icon-facebook.svg",
                                  alt: "facebook",
                                  width: "10",
                                  height: "20",
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "/" +
                              _vm.card.community_url +
                              "/" +
                              _vm.card.dyadey_id,
                          },
                        },
                        [
                          _vm.card.post_network_id ===
                          _vm.$simpleStore.Networks.TW
                            ? _c("img", {
                                attrs: {
                                  src: "/img/icons/icon-twitter.svg",
                                  alt: "twitter",
                                  width: "24",
                                  height: "20",
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "/" +
                              _vm.card.community_url +
                              "/" +
                              _vm.card.dyadey_id,
                          },
                        },
                        [
                          _vm.card.post_network_id ===
                          _vm.$simpleStore.Networks.IN
                            ? _c("img", {
                                attrs: {
                                  src: "/img/icons/icon-instagram.svg",
                                  alt: "instagram",
                                  width: "20",
                                  height: "20",
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "/" +
                              _vm.card.community_url +
                              "/" +
                              _vm.card.dyadey_id,
                          },
                        },
                        [
                          _vm.card.post_network_id ===
                          _vm.$simpleStore.Networks.YT
                            ? _c("img", {
                                attrs: {
                                  src: "/img/icons/icon-youtube.png",
                                  alt: "youtube",
                                  width: "28",
                                  height: "20",
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.card.children, function (dupe) {
                        return [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/" +
                                  _vm.card.community_url +
                                  "/" +
                                  _vm.card.dyadey_id,
                              },
                            },
                            [
                              dupe.network_id === _vm.$simpleStore.Networks.FB
                                ? _c("img", {
                                    attrs: {
                                      src: "/img/icons/icon-facebook.svg",
                                      alt: "facebook",
                                      width: "10",
                                      height: "20",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/" +
                                  _vm.card.community_url +
                                  "/" +
                                  _vm.card.dyadey_id,
                              },
                            },
                            [
                              dupe.network_id === _vm.$simpleStore.Networks.TW
                                ? _c("img", {
                                    attrs: {
                                      src: "/img/icons/icon-twitter.svg",
                                      alt: "twitter",
                                      width: "24",
                                      height: "20",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/" +
                                  _vm.card.community_url +
                                  "/" +
                                  _vm.card.dyadey_id,
                              },
                            },
                            [
                              dupe.network_id === _vm.$simpleStore.Networks.IN
                                ? _c("img", {
                                    attrs: {
                                      src: "/img/icons/icon-instagram.svg",
                                      alt: "instagram",
                                      width: "20",
                                      height: "20",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/" +
                                  _vm.card.community_url +
                                  "/" +
                                  _vm.card.dyadey_id,
                              },
                            },
                            [
                              dupe.network_id === _vm.$simpleStore.Networks.YT
                                ? _c("img", {
                                    attrs: {
                                      src: "/img/icons/icon-youtube.png",
                                      alt: "youtube",
                                      width: "28",
                                      height: "20",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ]),
            _vm._v(" "),
            _vm.card.post_parent_id > 0
              ? _c("strong", [_vm._v(_vm._s(_vm.card.post_reposted_from))])
              : _c(
                  "strong",
                  [
                    _vm.card.community_id !== _vm.$root.placeholderCommunityId
                      ? [
                          _c(
                            "a",
                            { attrs: { href: "/" + _vm.card.community_url } },
                            [_vm._v(_vm._s(_vm.card.community_name))]
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.card.community_name) +
                              "\n                    "
                          ),
                        ],
                  ],
                  2
                ),
            _vm._v(" "),
            _c(
              "time",
              {
                attrs: {
                  datetime: _vm.card.post_birthday_short,
                  title: "posted on " + _vm.card.post_birthday_full,
                },
              },
              [_vm._v(_vm._s(_vm.card.post_birthday_long))]
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.card.post_network_id === _vm.$simpleStore.Networks.DY &&
        _vm.card.post_is_draft === false
          ? _c(
              "a",
              {
                staticClass: "c-card__content-wrap",
                attrs: {
                  href: "/" + _vm.card.community_url + "/" + _vm.card.dyadey_id,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "c-card__media",
                    attrs: { "data-card-type": _vm.card.post_type },
                  },
                  [
                    _vm.card.post_type === "video"
                      ? _c("img", {
                          staticClass: "play-icon",
                          attrs: {
                            src: _vm.videoPlayIcon,
                            alt: "video will play from article link",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.card.post_thumbnail
                      ? _c("img", {
                          attrs: {
                            src: _vm.card.post_thumbnail,
                            alt: _vm.card.post_title,
                          },
                        })
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "title" }, [
                  _c("strong", {
                    domProps: { innerHTML: _vm._s(_vm.card.post_title) },
                  }),
                ]),
              ]
            )
          : _vm.card.post_is_draft === false
          ? _c(
              "a",
              {
                staticClass: "c-card__content-wrap",
                attrs: {
                  href: "/" + _vm.card.community_url + "/" + _vm.card.dyadey_id,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "c-card__media",
                    attrs: { "data-card-type": _vm.card.post_type },
                  },
                  [
                    _vm.card.post_type.indexOf("video") !== -1
                      ? _c("img", {
                          staticClass: "play-icon",
                          attrs: {
                            src: _vm.videoPlayIcon,
                            alt: "video will play from article link",
                            width: "530",
                            height: "530",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.card.post_network_id !== _vm.$simpleStore.Networks.IN &&
                    _vm.card.post_thumbnail
                      ? _c(
                          "img",
                          _vm._b(
                            {
                              attrs: {
                                src: _vm.getPostImage(_vm.card.post_thumbnail),
                                alt: _vm.card.post_title,
                              },
                              on: {
                                error: (event) =>
                                  _vm.updatePost(event, _vm.card),
                              },
                            },
                            "img",
                            _vm.postImageDimensions,
                            false
                          )
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.card.post_network_id === _vm.$simpleStore.Networks.IN &&
                    _vm.card.post_type !== "video" &&
                    _vm.card.post_thumbnail
                      ? _c(
                          "img",
                          _vm._b(
                            {
                              attrs: {
                                src: _vm.getPostImage(_vm.card.post_thumbnail),
                                alt: _vm.card.community_name,
                              },
                              on: {
                                error: (event) =>
                                  _vm.updatePost(event, _vm.card),
                              },
                            },
                            "img",
                            _vm.postImageDimensions,
                            false
                          )
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.card.post_network_id === _vm.$simpleStore.Networks.IN &&
                    _vm.card.post_type === "video"
                      ? _c(
                          "img",
                          _vm._b(
                            {
                              attrs: {
                                src:
                                  _vm.card.post_thumbnail == null ||
                                  _vm.card.post_thumbnail.indexOf("http") === -1
                                    ? "https://dyadey.com" +
                                      _vm.card.post_thumbnail
                                    : _vm.card.post_thumbnail,
                                alt: _vm.card.community_name,
                              },
                              on: {
                                error: (event) =>
                                  _vm.updatePost(event, _vm.card),
                              },
                            },
                            "img",
                            _vm.postImageDimensions,
                            false
                          )
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _vm.card.post_title != null &&
                _vm.card.post_content.indexOf(_vm.card.post_title) !== 0 &&
                _vm.card.post_title !== "Timeline Photos" &&
                _vm.card.post_title.indexOf("This content isn't available") ===
                  -1
                  ? [
                      _vm.card.post_title &&
                      _vm.card.post_network_id !== _vm.$simpleStore.Networks.TW
                        ? _c("p", { staticClass: "title" }, [
                            _c("strong", {
                              domProps: {
                                innerHTML: _vm._s(_vm.card.post_title),
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.card.post_content &&
                _vm.card.post_network_id !== _vm.$simpleStore.Networks.YT
                  ? _c("div", {
                      directives: [
                        { name: "twitteremoji", rawName: "v-twitteremoji" },
                      ],
                      staticClass: "c-card__content",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$simpleStore.highlightHashtags(
                            _vm.card.post_content,
                            _vm.card.community_url
                          )
                        ),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.embeddedTweet
                  ? [
                      _c("div", { staticClass: "c-card--tweet" }, [
                        _c("div", { staticClass: "content" }, [
                          _c("div", { staticClass: "profile" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.embeddedTweet.profile_image,
                                alt: "profile image",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.embeddedTweet.link,
                                  target: "_blank",
                                },
                              },
                              [_vm._v("@" + _vm._s(_vm.embeddedTweet.user))]
                            ),
                            _vm._v(
                              "\n                            ·\n                            " +
                                _vm._s(_vm.embeddedTweet.created) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", {
                            directives: [
                              {
                                name: "twitteremoji",
                                rawName: "v-twitteremoji",
                              },
                            ],
                            domProps: {
                              innerHTML: _vm._s(_vm.embeddedTweet.text),
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _vm.embeddedTweet.media != null
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.embeddedTweet.link,
                                  target: "_blank",
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "media",
                                  attrs: {
                                    src: _vm.embeddedTweet.media.url,
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            )
          : _c(
              "a",
              {
                staticClass: "c-card__content-wrap",
                attrs: { href: "/post/edit/" + _vm.card.post_id },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "c-card__media",
                    attrs: { "data-card-type": _vm.card.post_type },
                  },
                  [
                    _vm.card.post_type === "video"
                      ? _c("img", {
                          staticClass: "play-icon",
                          attrs: {
                            src: _vm.videoPlayIcon,
                            alt: "video will play from article link",
                            width: "530",
                            height: "530",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.card.post_thumbnail
                      ? _c("img", {
                          attrs: {
                            src: _vm.card.post_thumbnail,
                            alt: _vm.card.post_title,
                          },
                        })
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _vm.card.post_title &&
                _vm.card.post_network_id !== _vm.$simpleStore.Networks.TW
                  ? _c("p", { staticClass: "title" }, [
                      _c("strong", {
                        domProps: { innerHTML: _vm._s(_vm.card.post_title) },
                      }),
                    ])
                  : _vm._e(),
              ]
            ),
        _vm._v(" "),
        _vm.card.post_is_draft !== true
          ? _c("div", { staticClass: "c-card__footer-wrap" }, [
              _c("footer", [
                _c("button", { staticClass: "c-card__comments" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "17",
                        height: "16",
                        viewBox: "0 0 17 16",
                      },
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            fill: "none",
                            "fill-rule": "evenodd",
                            stroke: "#979797",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M15.0252877,15.5000751 C14.9717256,15.4055384 14.9191788,15.305722 14.8680413,15.2005709 C14.5204601,14.4858593 14.2862049,13.6272452 14.2138943,12.6206449 L14.2008292,12.4387725 L14.3079116,12.2911866 C15.2177026,11.0372712 15.7139058,9.5386875 15.7139058,7.96578705 C15.7139058,3.84453177 12.3101039,0.5 8.10695292,0.5 C3.90380196,0.5 0.5,3.84453177 0.5,7.96578705 C0.5,12.0870423 3.90380196,15.4315741 8.10695292,15.4315741 C9.41339118,15.4315741 10.6708686,15.1083688 11.7878385,14.5009944 L12.0864836,14.3386003 L12.3473274,14.556594 C13.1050119,15.18981 13.9912721,15.5044801 15.0252877,15.5000751 Z",
                            },
                          }),
                          _vm._v(" "),
                          _c("circle", { attrs: { cx: "5", cy: "8", r: "1" } }),
                          _vm._v(" "),
                          _c("circle", { attrs: { cx: "8", cy: "8", r: "1" } }),
                          _vm._v(" "),
                          _c("circle", {
                            attrs: { cx: "11", cy: "8", r: "1" },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.card.comments))]),
                ]),
                _vm._v(" "),
                _c("button", { staticClass: "c-card__reposts" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "24",
                        height: "16",
                        viewBox: "0 0 24 16",
                      },
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            fill: "none",
                            "fill-rule": "evenodd",
                            stroke: "#979797",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M22.7889912,15.3679862 C22.7375028,15.2745349 22.6869952,15.1758984 22.6378438,15.0720237 C22.3026802,14.363702 22.0768581,13.5130057 22.0071548,12.5157475 L21.9947345,12.3380487 L22.0974671,12.192525 C22.9754284,10.9488662 23.4545954,9.46153541 23.4545954,7.90000025 C23.4545954,3.81199843 20.1750031,0.5 16.1318924,0.5 C12.0887818,0.5 8.80918946,3.81199843 8.80918946,7.90000025 C8.80918946,11.9880021 12.0887818,15.3000005 16.1318924,15.3000005 C17.3874631,15.3000005 18.596137,14.9807096 19.67027,14.3804041 L19.9750919,14.2100469 L20.239986,14.437575 C20.9626919,15.0583359 21.8056952,15.3684043 22.7889912,15.3679862 Z",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              d: "M14.4798017,15.3679862 C14.4283134,15.2745349 14.3778058,15.1758984 14.3286543,15.0720237 C13.9934908,14.363702 13.7676687,13.5130057 13.6979653,12.5157475 L13.6855451,12.3380487 L13.7882776,12.192525 C14.6662389,10.9488662 15.1454059,9.46153541 15.1454059,7.90000025 C15.1454059,3.81199843 11.8658137,0.5 7.82270297,0.5 C3.7795923,0.5 0.5,3.81199843 0.5,7.90000025 C0.5,11.9880021 3.7795923,15.3000005 7.82270297,15.3000005 C9.07827364,15.3000005 10.2869475,14.9807096 11.3610805,14.3804041 L11.6659024,14.2100469 L11.9307966,14.437575 C12.6535024,15.0583359 13.4965058,15.3684043 14.4798017,15.3679862 Z",
                              transform: "matrix(-1 0 0 1 15.645 0)",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("0")]),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "c-card__likes",
                    class: { liked: _vm.postLiked > 0 },
                    attrs: { "data-cy": "like-post" },
                    on: {
                      click: function ($event) {
                        return _vm.like(false, _vm.card)
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "17",
                          height: "16",
                          viewBox: "0 0 17 16",
                        },
                      },
                      [
                        _c("defs", [
                          _c("path", {
                            attrs: {
                              d: "M17,5.18420563 C17,2.3210388 14.6586159,0 11.7703517,0 C10.5319425,0 9.39596622,0.428901458 8.5,1.14203776 C7.60403378,0.428901458 6.46805748,0 5.2296483,0 C2.34138409,0 0,2.3210388 0,5.18420563 C0,6.53676412 0.526736417,7.76419979 1.38241523,8.68721952 L7.21466028,15.4226081 C7.2502332,15.4684936 7.28794907,15.5126796 7.32866507,15.5541039 C7.60574814,15.8321782 7.98012101,16 8.39413841,16 C8.8081558,16 9.18252868,15.8321782 9.45232573,15.5617515 C9.49925627,15.5122547 9.52582882,15.4831514 9.5509013,15.4525611 L15.6250851,8.68721952 C16.4732636,7.76419979 17,6.53676412 17,5.18420563 Z",
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "g",
                          { attrs: { fill: "none", "fill-rule": "evenodd" } },
                          [
                            _c("path", {
                              attrs: {
                                stroke: "#888",
                                d: "M16.5,5.18420563 C16.5,2.59816271 14.3834456,0.5 11.7703517,0.5 C10.6797153,0.5 9.64690314,0.868217049 8.81137834,1.53324554 L8.5,1.78108435 L8.18862166,1.53324554 C7.35309686,0.868217049 6.32028473,0.5 5.2296483,0.5 C2.61655436,0.5 0.5,2.59816271 0.5,5.18420563 C0.5,6.37005443 0.95018776,7.48551872 1.76040116,8.35991746 L7.609819,15.1162593 C7.63588257,15.1498787 7.6608115,15.1787446 7.68284916,15.2011824 C7.87441495,15.3934335 8.1244312,15.5 8.39413841,15.5 C8.66292177,15.5 8.91335152,15.3940512 9.08949156,15.2177292 C9.13138797,15.1735418 9.14959901,15.1534179 9.17885461,15.1185233 L15.256921,8.34890727 C16.0511659,7.48458011 16.5,6.36808352 16.5,5.18420563 Z",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.card.likes))]),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.menu === "drafts"
        ? _c(
            "div",
            { class: ["c-card__options", { open: _vm.showContextMenu }] },
            [
              _c("ul", [
                _c("li", [
                  _c(
                    "a",
                    { attrs: { href: "/post/edit/" + _vm.card.post_id } },
                    [_vm._v("Edit Draft")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.modalDeleteDraft(_vm.card.post_id)
                        },
                      },
                    },
                    [_vm._v("Delete Draft")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.toggleContextMenu.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ]),
              ]),
            ]
          )
        : _vm.menu === "posts"
        ? _c(
            "div",
            { class: ["c-card__options", { open: _vm.showContextMenu }] },
            [
              _c("ul", [
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "/" +
                          _vm.card.community_url +
                          "/" +
                          _vm.card.dyadey_id,
                      },
                    },
                    [_vm._v("Go to Post")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    { attrs: { href: "/post/edit/" + _vm.card.post_id } },
                    [_vm._v("Edit Post")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.modalDeletePost(_vm.card.post_id)
                        },
                      },
                    },
                    [_vm._v("Delete Post")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.toggleContextMenu.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ]),
              ]),
            ]
          )
        : _c(
            "div",
            { class: ["c-card__options", { open: _vm.showContextMenu }] },
            [
              _c("ul", [
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "/" +
                          _vm.card.community_url +
                          "/" +
                          _vm.card.dyadey_id,
                      },
                    },
                    [_vm._v("Go to Post")]
                  ),
                ]),
                _vm._v(" "),
                typeof _vm.$root.community == "undefined"
                  ? _c("li", [
                      _c(
                        "a",
                        { attrs: { href: "/" + _vm.card.community_url } },
                        [_vm._v("Go to Community")]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      staticClass: "post-copy-link",
                      attrs: {
                        href: "#",
                        "data-link":
                          "/" +
                          _vm.card.community_url +
                          "/" +
                          _vm.card.dyadey_id,
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.copyLink.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v("Copy the URL "),
                      _vm.copySuccess ? _c("span", [_vm._v("✅")]) : _vm._e(),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm.card.post_network_id !== _vm.$simpleStore.Networks.DY
                  ? _c("li", [
                      _vm.card.post_network_id !== _vm.$simpleStore.Networks.IN
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.card.post_media,
                                target: "_blank",
                              },
                            },
                            [_vm._v("View Original Source")]
                          )
                        : _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.card.post_source,
                                target: "_blank",
                              },
                            },
                            [_vm._v("View Original Source")]
                          ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.admin
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.modalDeletePost(_vm.card.post_id)
                            },
                          },
                        },
                        [
                          _vm._v("Delete Post "),
                          _c("em", [_vm._v("(admin only)")]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.toggleContextMenu.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ]),
              ]),
            ]
          ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.showDeleteModal
          ? _c("div", { staticClass: "c-cards__menu--delete" }, [
              _c("div", { staticClass: "o-grid o-grid--center u-bg-white" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "o-grid__item u-width-full u-a-center u-p-y6 u-p-x6",
                  },
                  [
                    _vm.showDeleteDraft === false
                      ? [
                          _c(
                            "h4",
                            {
                              staticClass:
                                "u-color-blue u-text-24 u-semibold u-m-b2",
                            },
                            [_vm._v("Delete Post")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "u-m-b3" }, [
                            _vm._v(
                              "Are you sure you want to delete this post?"
                            ),
                          ]),
                        ]
                      : [
                          _c(
                            "h4",
                            {
                              staticClass:
                                "u-color-blue u-text-24 u-semibold u-m-b2",
                            },
                            [_vm._v("Delete Draft")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "u-m-b3" }, [
                            _vm._v(
                              "Are you sure you want to delete this post?"
                            ),
                          ]),
                        ],
                    _vm._v(" "),
                    _c("div", { staticClass: "u-flex u-flex-justify-center" }, [
                      _vm.$root.user !== false &&
                      _vm.card.user_id === _vm.$root.user.id
                        ? _c(
                            "button",
                            {
                              staticClass: "c-btn c-btn--red u-m-r3",
                              on: {
                                click: function ($event) {
                                  return _vm.deletePost(
                                    _vm.card.post_id,
                                    _vm.showDeleteDraft
                                  )
                                },
                              },
                            },
                            [_vm._v("Yes delete my " + _vm._s(_vm.item))]
                          )
                        : _vm.admin === true
                        ? _c(
                            "button",
                            {
                              staticClass: "c-btn c-btn--red u-m-r3",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteCommunityPost(
                                    _vm.card.post_id,
                                    _vm.card.community_id
                                  )
                                },
                              },
                            },
                            [_vm._v("Yes delete community post")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "c-btn c-btn--red is-outline",
                          on: { click: _vm.closeModal },
                        },
                        [_vm._v("Cancel")]
                      ),
                    ]),
                  ],
                  2
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("small", [
      _c("span", { staticClass: "c-card__postedin" }, [_vm._v("Draft")]),
      _vm._v(" posting in"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("small", [
      _c("span", { staticClass: "c-card__postedin" }, [_vm._v("Draft Post")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }