<template>
    <article class="c-card" :data-did="card.dyadey_id" data-cy="card">
        <div class="c-card__inner">
            <aside :class="['c-card__menu-cta', { 'open': showContextMenu }]" @click.prevent="toggleContextMenu">&nbsp;</aside>
            <header>
                <figure v-bind:class="[card.post_network_id === $simpleStore.Networks.DY && card.post_show_community_img !== 1 && typeof $root.community != 'undefined' && $root.community.id > 0 ? 'ugc' : '']">
                    <a v-if="card.post_network_id === $simpleStore.Networks.DY && card.post_show_community_img !== 1 && typeof $root.community != 'undefined' && $root.community.id > 0" :href="'/u/' + card.user_id">
                        <img :src="card.user_avatar" :alt="card.cAlt" width="50" height="50">
                    </a>
                    <a class="c-cards__default-community-icon" v-else :href="'/' + card.community_url">
                        <img v-if="card.community_id > 0 && parseInt(card.community_id) !== $root.placeholderCommunityId" :src="$simpleStore.cloudinaryUrl(50, 50, 'img/communities/' + card.community_id + '/' +  card.community_thumbnail, $root)" :alt="card.community_name" width="50" height="50">
                        <img v-else src="/img/icons/icon-communities-squared--contained.svg" width="50" height="50" alt="Default Community Image">
                    </a>
                </figure>
                <section>
                    <div v-if="card.repost_id > 0" class="u-flex u-flex-align-start">
                        <small><span class="c-card__postedin"><a :href="'/u/' + card.username">{{ card.user_forename }} {{ card.user_surname }}</a></span>reposted from</small>
                    </div>
                    <div v-else-if="card.post_network_id === 7 && card.post_is_draft === true && card.community_id > 1" class="u-flex u-flex-align-start">
                        <small><span class="c-card__postedin">Draft</span> posting in</small>
                    </div>
                    <div v-else-if="card.post_is_draft === true" class="u-flex u-flex-align-start">
                        <small><span class="c-card__postedin">Draft Post</span></small>
                    </div>
                    <div v-else-if="card.post_network_id === 7" class="u-flex u-flex-align-start">
                        <small><span class="c-card__postedin"><a :href="'/u/' + card.username">{{ card.user_forename }} {{ card.user_surname }}</a></span>posted in</small>
                    </div>
                    <div v-else class="u-flex u-flex-align-center">
                        <small>Post from</small>
                        <div class="c-card__social-from">
                            <a :href="'/' + card.community_url + '/' + card.dyadey_id"><img v-if="card.post_network_id === $simpleStore.Networks.FB" src="/img/icons/icon-facebook.svg"  alt="facebook" width="10" height="20"></a>
                            <a :href="'/' + card.community_url + '/' + card.dyadey_id"><img v-if="card.post_network_id === $simpleStore.Networks.TW" src="/img/icons/icon-twitter.svg"   alt="twitter" width="24" height="20"></a>
                            <a :href="'/' + card.community_url + '/' + card.dyadey_id"><img v-if="card.post_network_id === $simpleStore.Networks.IN" src="/img/icons/icon-instagram.svg" alt="instagram" width="20" height="20"></a>
                            <a :href="'/' + card.community_url + '/' + card.dyadey_id"><img v-if="card.post_network_id === $simpleStore.Networks.YT" src="/img/icons/icon-youtube.png"   alt="youtube" width="28" height="20"></a>
                            <template v-for="dupe in card.children">
                                <a :href="'/' + card.community_url + '/' + card.dyadey_id"><img v-if="dupe.network_id === $simpleStore.Networks.FB" src="/img/icons/icon-facebook.svg"  alt="facebook" width="10" height="20"></a>
                                <a :href="'/' + card.community_url + '/' + card.dyadey_id"><img v-if="dupe.network_id === $simpleStore.Networks.TW" src="/img/icons/icon-twitter.svg"   alt="twitter" width="24" height="20"></a>
                                <a :href="'/' + card.community_url + '/' + card.dyadey_id"><img v-if="dupe.network_id === $simpleStore.Networks.IN" src="/img/icons/icon-instagram.svg" alt="instagram" width="20" height="20"></a>
                                <a :href="'/' + card.community_url + '/' + card.dyadey_id"><img v-if="dupe.network_id === $simpleStore.Networks.YT" src="/img/icons/icon-youtube.png"   alt="youtube" width="28" height="20"></a>
                            </template>
                        </div>
                    </div>
                    <strong v-if="card.post_parent_id > 0">{{ card.post_reposted_from }}</strong>
                    <strong v-else>
                        <template v-if="card.community_id !== $root.placeholderCommunityId">
                            <a :href="'/' + card.community_url">{{ card.community_name }}</a>
                        </template>
                        <template v-else>
                            {{ card.community_name }}
                        </template>
                    </strong>
                    <time :datetime="card.post_birthday_short" :title="'posted on ' + card.post_birthday_full">{{ card.post_birthday_long }}</time>
                </section>
            </header>
            <a v-if="card.post_network_id === $simpleStore.Networks.DY && card.post_is_draft === false" class="c-card__content-wrap" :href="'/' + card.community_url + '/' + card.dyadey_id">
                <div class="c-card__media" :data-card-type="card.post_type">
                    <img v-if="card.post_type === 'video'" :src="videoPlayIcon" class="play-icon" alt="video will play from article link">
                    <img v-if="card.post_thumbnail" :src="card.post_thumbnail" :alt="card.post_title">
                </div>
                <!--
                <div v-if="card.post_network_id == $simpleStore.Networks.YT" class="c-video-wrapper" :data-url="'/' + card.community_url + '/' + card.post_id">
                    <div :id="'player-' + card.post_video_id" class="community-player" :data-vid="card.post_video_id"></div>
                </div>
                -->
                <p class="title"><strong v-html="card.post_title"></strong></p>
            </a>
            <a v-else-if="card.post_is_draft === false" class="c-card__content-wrap" :href="'/' + card.community_url + '/' + card.dyadey_id">
                <div class="c-card__media" :data-card-type="card.post_type">
                    <img v-if="card.post_type.indexOf('video') !== -1" :src="videoPlayIcon" class="play-icon" alt="video will play from article link" width="530" height="530">
                    <img v-if="card.post_network_id !== $simpleStore.Networks.IN && card.post_thumbnail" :src="getPostImage(card.post_thumbnail)" :alt="card.post_title" @error="(event) => updatePost(event, card)" v-bind="postImageDimensions">
                    <img v-if="card.post_network_id === $simpleStore.Networks.IN && card.post_type !== 'video' && card.post_thumbnail" :src="getPostImage(card.post_thumbnail)" :alt="card.community_name" @error="(event) => updatePost(event, card)" v-bind="postImageDimensions">
                    <img v-if="card.post_network_id === $simpleStore.Networks.IN && card.post_type === 'video'" :src="card.post_thumbnail == null || card.post_thumbnail.indexOf('http') === -1 ? 'https://dyadey.com' + card.post_thumbnail : card.post_thumbnail" :alt="card.community_name" @error="(event) => updatePost(event, card)" v-bind="postImageDimensions">
                </div>
                <!--
                <div v-if="card.post_network_id == $simpleStore.Networks.YT" class="c-video-wrapper" :data-url="'/' + card.community_url + '/' + card.post_id">
                    <div :id="'player-' + card.post_video_id" class="community-player" :data-vid="card.post_video_id"></div>
                </div>
                -->
                <!-- do not display title if is the also the start of the content -->
                <template v-if="card.post_title != null && card.post_content.indexOf(card.post_title) !== 0 && card.post_title !== 'Timeline Photos' && card.post_title.indexOf('This content isn\'t available') === -1">
                    <p class="title" v-if="card.post_title && card.post_network_id !== $simpleStore.Networks.TW"><strong v-html="card.post_title"></strong></p>
                </template>
                <div class="c-card__content" v-if="card.post_content && (card.post_network_id !== $simpleStore.Networks.YT)" v-html="$simpleStore.highlightHashtags(card.post_content, card.community_url)" v-twitteremoji></div>
                <template v-if="embeddedTweet">
                    <div class="c-card--tweet">
                        <div class="content">
                            <div class="profile">
                                <img :src="embeddedTweet.profile_image" alt="profile image">
                                <a :href="embeddedTweet.link" target="_blank">@{{embeddedTweet.user}}</a>
                                &#183;
                                {{embeddedTweet.created}}
                            </div>
                            <p v-html="embeddedTweet.text" v-twitteremoji></p>
                        </div>
                        <a v-if="embeddedTweet.media != null" :href="embeddedTweet.link" target="_blank"><img :src="embeddedTweet.media.url" alt="" class="media"></a>
                    </div>
                </template>
            </a>
            <a v-else class="c-card__content-wrap" :href="'/post/edit/' + card.post_id">
                <div class="c-card__media" :data-card-type="card.post_type">
                    <img v-if="card.post_type === 'video'" :src="videoPlayIcon" class="play-icon" alt="video will play from article link" width="530" height="530">
                    <img v-if="card.post_thumbnail" :src="card.post_thumbnail" :alt="card.post_title">
                </div>
                <!--
                <div v-if="card.post_network_id == $simpleStore.Networks.YT" class="c-video-wrapper" :data-url="'/' + card.community_url + '/' + card.post_id">
                    <div :id="'player-' + card.post_video_id" class="community-player" :data-vid="card.post_video_id"></div>
                </div>
                -->
                <p class="title" v-if="card.post_title && card.post_network_id !== $simpleStore.Networks.TW"><strong v-html="card.post_title"></strong></p>
                <!--<div class="c-card__content" v-if="card.post_content && card.post_network_id != $simpleStore.Networks.YT" v-html="card.post_content"></div>-->
            </a>
            <div class="c-card__footer-wrap" v-if="card.post_is_draft !== true">
                <footer>
                    <button class="c-card__comments">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16">
                            <g fill="none" fill-rule="evenodd" stroke="#979797">
                                <path d="M15.0252877,15.5000751 C14.9717256,15.4055384 14.9191788,15.305722 14.8680413,15.2005709 C14.5204601,14.4858593 14.2862049,13.6272452 14.2138943,12.6206449 L14.2008292,12.4387725 L14.3079116,12.2911866 C15.2177026,11.0372712 15.7139058,9.5386875 15.7139058,7.96578705 C15.7139058,3.84453177 12.3101039,0.5 8.10695292,0.5 C3.90380196,0.5 0.5,3.84453177 0.5,7.96578705 C0.5,12.0870423 3.90380196,15.4315741 8.10695292,15.4315741 C9.41339118,15.4315741 10.6708686,15.1083688 11.7878385,14.5009944 L12.0864836,14.3386003 L12.3473274,14.556594 C13.1050119,15.18981 13.9912721,15.5044801 15.0252877,15.5000751 Z"/>
                                <circle cx="5" cy="8" r="1"/>
                                <circle cx="8" cy="8" r="1"/>
                                <circle cx="11" cy="8" r="1"/>
                            </g>
                        </svg>
                        <span>{{ card.comments }}</span>
                    </button>
                    <button class="c-card__reposts">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">
                            <g fill="none" fill-rule="evenodd" stroke="#979797">
                                <path d="M22.7889912,15.3679862 C22.7375028,15.2745349 22.6869952,15.1758984 22.6378438,15.0720237 C22.3026802,14.363702 22.0768581,13.5130057 22.0071548,12.5157475 L21.9947345,12.3380487 L22.0974671,12.192525 C22.9754284,10.9488662 23.4545954,9.46153541 23.4545954,7.90000025 C23.4545954,3.81199843 20.1750031,0.5 16.1318924,0.5 C12.0887818,0.5 8.80918946,3.81199843 8.80918946,7.90000025 C8.80918946,11.9880021 12.0887818,15.3000005 16.1318924,15.3000005 C17.3874631,15.3000005 18.596137,14.9807096 19.67027,14.3804041 L19.9750919,14.2100469 L20.239986,14.437575 C20.9626919,15.0583359 21.8056952,15.3684043 22.7889912,15.3679862 Z"/>
                                <path d="M14.4798017,15.3679862 C14.4283134,15.2745349 14.3778058,15.1758984 14.3286543,15.0720237 C13.9934908,14.363702 13.7676687,13.5130057 13.6979653,12.5157475 L13.6855451,12.3380487 L13.7882776,12.192525 C14.6662389,10.9488662 15.1454059,9.46153541 15.1454059,7.90000025 C15.1454059,3.81199843 11.8658137,0.5 7.82270297,0.5 C3.7795923,0.5 0.5,3.81199843 0.5,7.90000025 C0.5,11.9880021 3.7795923,15.3000005 7.82270297,15.3000005 C9.07827364,15.3000005 10.2869475,14.9807096 11.3610805,14.3804041 L11.6659024,14.2100469 L11.9307966,14.437575 C12.6535024,15.0583359 13.4965058,15.3684043 14.4798017,15.3679862 Z" transform="matrix(-1 0 0 1 15.645 0)"/>
                            </g>
                        </svg>
                        <span>0</span>
                    </button>
                    <button data-cy="like-post" class="c-card__likes" @click="like(false, card)" :class="{liked: (postLiked > 0)}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16">
                            <defs>
                                <path d="M17,5.18420563 C17,2.3210388 14.6586159,0 11.7703517,0 C10.5319425,0 9.39596622,0.428901458 8.5,1.14203776 C7.60403378,0.428901458 6.46805748,0 5.2296483,0 C2.34138409,0 0,2.3210388 0,5.18420563 C0,6.53676412 0.526736417,7.76419979 1.38241523,8.68721952 L7.21466028,15.4226081 C7.2502332,15.4684936 7.28794907,15.5126796 7.32866507,15.5541039 C7.60574814,15.8321782 7.98012101,16 8.39413841,16 C8.8081558,16 9.18252868,15.8321782 9.45232573,15.5617515 C9.49925627,15.5122547 9.52582882,15.4831514 9.5509013,15.4525611 L15.6250851,8.68721952 C16.4732636,7.76419979 17,6.53676412 17,5.18420563 Z"/>
                            </defs>
                            <g fill="none" fill-rule="evenodd">
                                <path stroke="#888" d="M16.5,5.18420563 C16.5,2.59816271 14.3834456,0.5 11.7703517,0.5 C10.6797153,0.5 9.64690314,0.868217049 8.81137834,1.53324554 L8.5,1.78108435 L8.18862166,1.53324554 C7.35309686,0.868217049 6.32028473,0.5 5.2296483,0.5 C2.61655436,0.5 0.5,2.59816271 0.5,5.18420563 C0.5,6.37005443 0.95018776,7.48551872 1.76040116,8.35991746 L7.609819,15.1162593 C7.63588257,15.1498787 7.6608115,15.1787446 7.68284916,15.2011824 C7.87441495,15.3934335 8.1244312,15.5 8.39413841,15.5 C8.66292177,15.5 8.91335152,15.3940512 9.08949156,15.2177292 C9.13138797,15.1735418 9.14959901,15.1534179 9.17885461,15.1185233 L15.256921,8.34890727 C16.0511659,7.48458011 16.5,6.36808352 16.5,5.18420563 Z"/>
                            </g>
                        </svg>
                        <span>{{ card.likes }}</span>
                    </button>
                </footer>
            </div>
        </div>
        <div v-if="menu === 'drafts'" :class="['c-card__options', { 'open': showContextMenu }]">
            <ul>
                <li><a :href="'/post/edit/' + card.post_id">Edit Draft</a></li>
                <li><a href="#" @click.prevent="modalDeleteDraft(card.post_id)">Delete Draft</a></li>
                <li><a href="#" @click.prevent="toggleContextMenu">Cancel</a></li>
            </ul>
        </div>
        <div v-else-if="menu === 'posts'" :class="['c-card__options', { 'open': showContextMenu }]">
            <ul>
                <li><a :href="'/' + card.community_url + '/' + card.dyadey_id">Go to Post</a></li>
                <li><a :href="'/post/edit/' + card.post_id">Edit Post</a></li>
                <li><a href="#" @click.prevent="modalDeletePost(card.post_id)">Delete Post</a></li>
                <li><a href="#" @click.prevent="toggleContextMenu">Cancel</a></li>
            </ul>
        </div>
        <div v-else :class="['c-card__options', { 'open': showContextMenu }]">
            <ul>
                <li><a :href="'/' + card.community_url + '/' + card.dyadey_id">Go to Post</a></li>
                <li v-if="typeof $root.community == 'undefined'"><a :href="'/' + card.community_url">Go to Community</a></li>
                <li><a class="post-copy-link" href="#" :data-link="'/' + card.community_url + '/' + card.dyadey_id" @click.prevent="copyLink">Copy the URL <span v-if="copySuccess">&#9989;</span></a></li>
                <!--<li><a href="#0">Share</a></li>
                <li><a href="#" :class="{'js-join-convo__cta': $parent.rU === false}">Flag Content</a></li>
                <li><a href="#" :class="{'js-join-convo__cta': $parent.rU === false}">Hide User Posts</a></li>
                <li><a href="#" :class="{'js-join-convo__cta': $parent.rU === false}">Report User</a></li>-->
                <li v-if="card.post_network_id !== $simpleStore.Networks.DY">
                    <a v-if="card.post_network_id !== $simpleStore.Networks.IN" :href="card.post_media" target="_blank">View Original Source</a>
                    <a v-else :href="card.post_source" target="_blank">View Original Source</a>
                </li>
                <li v-if="admin"><a href="#" @click.prevent="modalDeletePost(card.post_id)">Delete Post <em>(admin only)</em></a></li>
                <li><a href="#" @click.prevent="toggleContextMenu">Cancel</a></li>
            </ul>
        </div>
        <transition name="fade">
            <div v-if="showDeleteModal" class="c-cards__menu--delete">
                <div class="o-grid o-grid--center u-bg-white">
                    <div class="o-grid__item u-width-full u-a-center u-p-y6 u-p-x6">
                        <template v-if="showDeleteDraft === false">
                            <h4 class="u-color-blue u-text-24 u-semibold u-m-b2">Delete Post</h4>
                            <p class="u-m-b3">Are you sure you want to delete this post?</p>
                        </template>
                        <template v-else>
                            <h4 class="u-color-blue u-text-24 u-semibold u-m-b2">Delete Draft</h4>
                            <p class="u-m-b3">Are you sure you want to delete this post?</p>
                        </template>
                        <div class="u-flex u-flex-justify-center">
                            <button v-if="$root.user !== false && card.user_id === $root.user.id" class="c-btn c-btn--red u-m-r3" @click="deletePost(card.post_id, showDeleteDraft)">Yes delete my {{ item }}</button>
                            <button v-else-if="admin === true" class="c-btn c-btn--red u-m-r3" @click="deleteCommunityPost(card.post_id, card.community_id)">Yes delete community post</button>
                            <button class="c-btn c-btn--red is-outline" @click="closeModal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </article>
</template>

<script>
import MasonryStore from "../configs/masonry-store";

export default {
    props: [
        'card',
        'page'
    ],
    data() {
        return {
            // other data
            //lazyLoaded: this.card.lz,
            admin: false,
            copySuccess: false,
            item: 'post',
            masonry: MasonryStore,
            message: this.$simpleStore.message,
            menu: this.$parent.$parent.menu,
            postLiked: typeof this.card.liked != 'undefined' && this.card.liked,
            postLikes: this.card.likes != null ? this.$simpleStore.formatValue(this.card.likes) : '0',
            showContextMenu: false,
            showDeleteDraft: false,
            showDeleteModal: false,
            thumb: null,
            videoPlayIcon: (this.$root.cloudinaryUrl ? this.$root.cloudinaryUrl + 'f_auto,dpr_' + this.$root.$dpr + ',q_auto' : '') + '/img/video-play-icon.png'
            //initialCards: this.$root.cards.cards
        }
    },
    created() {
        this.$root.$on('menu-toggle-card', (data) => {
            this.toggleContextMenu(true);
        });
    },
    computed: {
        embeddedTweet() {
            let metaData = JSON.parse(this.card.meta_data);
            if (typeof metaData.twitter_embed != 'undefined') {
                let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                let createdDate = new Date(metaData.twitter_embed.created * 1000);
                createdDate = createdDate.getDate() + ' ' + months[createdDate.getMonth()] + ' ' + createdDate.getFullYear();
                metaData.twitter_embed.created = createdDate;
                return metaData.twitter_embed;
            }
            return null;
        },
        postImageDimensions() {
            let metaData = JSON.parse(this.card.meta_data);
            if (typeof metaData.thumbnail_dimensions != 'undefined') {
                let regexDims = /width="(\d+)" height="(\d+)"/g
                let match = regexDims.exec(metaData.thumbnail_dimensions);
                if (match == null) {
                    return {};
                }
                return {
                    width: match[1],
                    height: match[2]
                }
            }
            if (typeof this.card.thumbnail_dimensions != 'undefined' && this.card.thumbnail_dimensions != null) {
                let regexDims = /width="(\d+)" height="(\d+)"/g
                let match = regexDims.exec(this.card.thumbnail_dimensions);
                if (match == null) {
                    return {};
                }
                return {
                    width: match[1],
                    height: match[2]
                }
            }
        }
    },
    mounted() {
        this.videoWidth();
    },
    methods: {
        closeModal() {
            this.showDeleteModal = false;
            this.showContextMenu = false;
            this.$root.$emit('unfade-page');
        },
        copyLink(event) {
            this.$simpleStore.copyLink(event);
            this.copySuccess = true;
            this.toggleContextMenu();
        },
        deletePost(postId, isDraft) {
            this.$http.post('/post/deletemine', { post_id: postId }).then(response => {
                if (response.data.error === false) {
                    if (isDraft) {
                        this.$root.$emit('profile-decrement-drafts');
                    } else {
                        this.$root.$emit('profile-decrement-posts');
                    }
                    this.$destroy();
                    this.$el.parentNode.removeChild(this.$el);
                    this.$root.$emit('unfade-page');
                    this.masonry.load();
                }
            }, response => {
                console.log('Errored:');
                console.log(response);
            });
        },
        deleteCommunityPost(postId, communityId) {
            if (this.$root.$children[0].admin !== true) {
                return;
            }
            this.$http.post('/post/admindelete', { post_id: postId }).then(response => {
                if (response.data.error === false) {
                    //document.querySelector("article[data-pid='" + response.data.post_id + "']").remove();
                    this.$destroy();
                    this.$el.parentNode.removeChild(this.$el);
                    this.$root.$emit('unfade-page');
                    sessionStorage.removeItem('c' + communityId);
                    this.masonry.load();
                    //window.location.reload();
                }
            }, response => {
                console.log('Errored:');
                console.log(response);
            });
        },
        like(confirmed, card) {
            if (this.$root.user !== false) {
                this.$simpleStore.like(this, confirmed, card.dyadey_id, card);
            } else {
                this.$simpleStore.showMemberPanel(this);
            }
        },
        modalDeleteDraft(postId) {
            this.item = 'draft';
            this.showDeleteDraft = true;
            this.showDeleteModal = true;
            // hide context menu without unfading background
            this.showContextMenu = false;
            this.$simpleStore.showContextMenu = false;
        },
        modalDeletePost(postId) {
            this.item = 'post';
            this.showDeleteDraft = false;
            this.showDeleteModal = true;
            // hide context menu without unfading background
            this.showContextMenu = false;
            this.$simpleStore.showContextMenu = false;
        },
        toggleContextMenu(clickOutside = false) {
            let appComponent = this.$root.$children[0];
            this.admin = appComponent.admin;
            this.showContextMenu = this.$simpleStore.contextMenu(this, clickOutside);
        },
        updatePost(ev, card) {
            let postId = card.post_id;
            let networkId = card.post_network_id;
            // only check FB and Insta posts
            if (networkId !== this.$simpleStore.Networks.FB && networkId !== this.$simpleStore.Networks.IN) {
                return;
            }
            // don't request more than 8 broken images at a time to avoid overloading server
            if (this.$simpleStore.imageUpdateRequests >= 7) {
                return;
            }
            this.$simpleStore.imageUpdateRequests++;
            this.$http.get('/api/v1/posts/update/' + networkId + '/' + postId).then(response => {
                this.$simpleStore.imageUpdateRequests--;
                if (response.data.success === true) {
                    // update media_url in sessionStorage
                    // this.$root.community may not be set if we are on global search page
                    if (typeof this.$root.community != 'undefined') {
                        let updatedId = response.data.post_id;
                        let commData = JSON.parse(sessionStorage.getItem('c' + this.$root.community.id));
                        for (let i = 0; i < commData[0].cards.length; i++) {
                            let sessionCard = commData[0].cards[i];
                            if (sessionCard.post_id === updatedId) {
                                if (typeof response.data.media_url != 'undefined') {
                                    sessionCard.post_media = response.data.media_url;
                                } else if (typeof response.data.post_thumbnail != 'undefined') {
                                    sessionCard.post_thumbnail = response.data.post_thumbnail;
                                }
                                commData[0].cards[i] = sessionCard;
                                sessionStorage.setItem('c' + this.$root.community.id, JSON.stringify(commData));
                                break;
                            }
                        }
                    }
                    if (typeof response.data.media_url != 'undefined') {
                        ev.target.src = response.data.media_url;
                    } else if (typeof response.data.post_thumbnail != 'undefined') {
                        ev.target.src = response.data.post_thumbnail;
                    }
                    this.masonry.images();
                }
            }, response => {
                console.log('Errored:');
                console.log(response);
            });
        },
        videoWidth() {
            let postView = document.getElementById('post-view');
            if (postView) {
                let pageHeader = document.querySelector('header.c-page-head.content');
                let articleHeader = document.querySelector('#post-view header');
                let pageHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
                let availableVideoHeight = pageHeight - articleHeader.offsetHeight - pageHeader.offsetHeight - 32;
            }
        },
        getPostImage(thumbnail) {
            // do not adjust non local images
            if (thumbnail.match(/^\/media\/images/) == null) {
                return thumbnail;
            }
            let mediaSize = this.$simpleStore.getMediaSize();
            return thumbnail.replace(/\.(jpg|jpeg|png|gif|webp)$/, '-' + mediaSize + '.$1');
        }
    },
    directives: {
        twitteremoji: {
            inserted(el) {
                if (typeof twemoji === 'object' && typeof twemoji.parse === 'function') {
                    twemoji.parse(el, {
                        folder: 'svg',
                        ext: '.svg'
                    });
                }
            }
        }
    }
}
</script>
